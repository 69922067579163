import React from 'react'

export const Downloads = () => {
    return (
        <div className=''>
           
         <table>
            <th colspan="3"><big>FSX/P3D Downloads</big></th>
            <tr>
                <td>
                RSSCT Setup FSX/P3D v2.0.4.3
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FRSSCT_setup_P3DFSX.exe?alt=media&token=d48e3733-1bac-487b-beb9-ce472ef2b01e">Download</a>
                </td>
                <td>28 MB</td>
            </tr>            

            <tr>
                <td>
                RS Common Package v1.6 FSX/P3D
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FCommon_patch1600.exe?alt=media&token=64164849-0b9c-4a7b-8f2d-9753b5bf1c34">Download</a>
                </td>
                <td>303 MB</td>
            </tr>

            <tr>
                <td>
                Antigua 2018 Update v1.3
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FAntigua2018_patch1300.exe?alt=media&token=b40871c8-e93a-4572-9136-edd1087e6d48">Download</a>
                </td>
                <td>6 MB</td>
            </tr>

            <tr>
                <td>
                Barbados 2017 Update v1.3
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FBarbados2017_patch1300.exe?alt=media&token=960da85f-dd5c-4cd9-a03b-3df1b02f2240">Download</a>
                </td>
                <td>7 MB</td>
            </tr>

            <tr>
                <td>
                St. Kitts 2017 Update v1.3
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FStKitts2017_patch1400.exe?alt=media&token=af3daa86-54ee-4217-b2e3-8b915870185c">Download</a>
                </td>
                <td>3 MB</td>
            </tr>

            <tr>
                <td>
                St Vincent 2019 HD Texture Pack
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FStVincent2019_HDTexturePack.exe?alt=media&token=830f2b74-7f36-43f2-a6d6-54d3c451bae7">Download</a>
                </td>
                <td>246 MB</td>
            </tr>

            <tr>
                <td>
                St Vincent 2019 Legacy Patch (for FSX and P3Dv1/2/3)
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FStVincent2019_LegacyPack.exe?alt=media&token=64cffad1-a979-4218-927b-ee4d7e670228">Download</a>
                </td>
                <td>210 MB</td>
            </tr>

            <tr>
                <td>
                St Vincent 2019 Update v1.4
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FStVincent2019_Patch1400.exe?alt=media&token=f14c89af-ee43-45d5-b613-5a3d1281acc9">Download</a>
                </td>
                <td>475 MB</td>
            </tr>

            <tr>
                <td>
                TBPB 2020 HD Texture Pack
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FTBPB2020_HDTexturePack.exe?alt=media&token=ad324a48-2ec2-41e4-a6d3-d7fc27c07999">Download</a>
                </td>
                <td>243 MB</td>
            </tr>

            <tr>
                <td>
                TBPB 2020 Legacy Pack (for FSX and P3Dv1/2/3)
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FTBPB2020_LegacyPack.exe?alt=media&token=fa532ac3-2405-46c8-a38e-272f9717bc8e">Download</a>
                </td>
                <td>348 MB</td>
            </tr>

            <tr>
                <td>
                TBPB 2020 Update v1.3
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FTBPB2020_Patch1300.exe?alt=media&token=affd10a7-d5c6-4626-bd3a-85ccc8280752">Download</a>
                </td>
                <td>310 MB</td>
            </tr>

        </table>
        
        <br/>
        <br/>
        
                
        <table>            
            <th colspan="3"><big>X-Plane Downloads</big></th>
            <tr>
                <td>
                RSSCT Setup X-Plane v2.0.4.3
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FRSSCT_setup_XP.exe?alt=media&token=640d9155-27a1-4f71-a76c-f4a9782888a6">Download</a>
                </td>
                <td>28 MB</td>
            </tr>

            <tr>
                <td>
                RS Cayman Islands W2XP Autogen Pack
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FRS_CaymanIslands_W2XP.zip?alt=media&token=951fb2c6-2e55-4219-9e1a-947609cf4005">Download</a>
                </td>
                <td>55 MB</td>
            </tr>
            
            <tr>
                <td>
                RS Dominica 2023 W2XP Autogen Pack
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FRS_Dominica_2023_W2XP.zip?alt=media&token=8a072b7f-2e06-4e30-8c3f-42f240bc1236">Download</a>
                </td>
                <td>55 MB</td>
            </tr>

            <tr>
                <td>
                RS TBPB 2020 W2XP Autogen Pack
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FRS_TBPB_2020_W2XP.zip?alt=media&token=0749c1bf-7923-46ea-abb9-0dc47c47de61">Download</a>
                </td>
                <td>61 MB</td>
            </tr>

            <tr>
                <td>
                RS St Vincent 2019  W2XP Autogen Pack
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FStVincent2019_W2XP.exe?alt=media&token=3b72c574-c9be-4bd1-9d6d-80ede46cfeca">Download</a>
                </td>
                <td>75 MB</td>
            </tr>

            <tr>
                <td>
                RS TBPB 2020 Traffic Flow Fix (XP11)
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FRS_TBPB_2020_XP11_Traffic_Flow_Fix.zip?alt=media&token=2ead5a31-498a-4e0f-b1ef-41868cc2a750">Download</a>
                </td>
                <td>0.25 MB</td>
            </tr>

            <tr>
                <td>
                RS St Vincent 2019 Traffic Flow Fix (XP11)
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FRS_TVSA_2019_XP11_Traffic_Flow_Fix.zip?alt=media&token=3bd19c03-a57b-4241-a9d8-0497ff429b82">Download</a>
                </td>
                <td>0.24 MB</td>
            </tr>

            <tr>
                <td>
                RS St Vincent 2019 HD Texture Pack
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FStVincent2019_XP_HDTexturePack.exe?alt=media&token=2dd5c912-e19b-4483-8346-a0c20761977a">Download</a>
                </td>
                <td>253 MB</td>
            </tr>

            <tr>
                <td>
                RS TBPB 2020 HD Texture Pack
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FTBPB2020_XP_HDTexturePack.exe?alt=media&token=883ff20d-70b0-4979-905f-ef4cedacea53">Download</a>
                </td>
                <td>243 MB</td>
            </tr>

            <tr>
                <td>
                RS Dominica 2023 Earth Nav Data (ATC fix for XP12)
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FXP12%20RS_Dominica_2023%20Earth%20nav%20data.7z?alt=media&token=9697b375-d1ec-4577-ae57-8c8d9b7e244b">Download</a>
                </td>
                <td>0.16 MB</td>
            </tr>

            <tr>
                <td>
                RS St Vincent 2019 Earth Nav Data (ATC fix for XP12)
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FXP12%20RS_St_Vincent_2019%20Earth%20nav%20data.7z?alt=media&token=72e2d990-93c6-4124-9bb5-df3a3da8abce">Download</a>
                </td>
                <td>0.21 MB</td>
            </tr>

            <tr>
                <td>
                RS TBPB 2020 Earth Nav Data (ATC fix for XP12)
                </td>
                <td>
                <a class="w3-button w3-yellow" href="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/downloads%2FXP12%20RS_TBPB_2020%20Earth%20nav%20data.7z?alt=media&token=373e7323-02d5-42e8-9528-69d71cf68e45">Download</a>
                </td>
                <td>0.27 MB</td>
            </tr>

        </table>   
            
        </div>
    )
}
